<template>
  <div>
    <!--begin::User-->
    <div>
      <h3 class="card-label">
        {{ $t('template_design.template_design') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>

      <div class="row">
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('template_design.upload_logo') }}</label>
                <upload-file
                    @file="listenerAttachment"
                    :inner-id="'logo'"
                    :placeholder="$t('template_design.upload_logo')"
                    :upload="dir_upload"
                    :start-link="'base'"
                    v-bind:valueprop="{name:'logo',path:logo_url}" v-if="reloadUploadAttachment">
                </upload-file>
                <span v-if="validation && validation.logo" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.logo[0] }}
                                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('template_design.color') }}</label>
                <div class="input-group">
                  <input type="color" class="form-control" v-model="data.color" :class="validation && validation.color ? 'is-invalid' : ''"/>
                </div>
                <span v-if="validation && validation.color" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.color[0] }}
                                </span>
              </div>
              <div class="col-lg-6 mb-5 pt-8">
                <b-form-checkbox size="lg" v-model="data.dark_mode" name="check-button" switch>{{ $t('template_design.dark_mode') }}</b-form-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>


        <div class="pl-0 pr-0">
          <div class="row">
            <div class="col-lg-6 mt-10">
              <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
            </div>
          </div>
        </div>


    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "form-smtp",
  data() {
    return {
      mainRoute: 'settings/template_design',
      dir_upload: 'template_design',
      data: {
        color: null,
        dark_mode: null,
      },
      idEdit: null,
      isEditing: false,
      validation: null,
      logo_url: null,
      reloadUploadAttachment: true,
    };
  },

  methods: {
    save() {
      this.data.dark_mode = this.data.dark_mode ? 1 : 0;
      if (this.idEdit) {
        this.update();
      } else {
        this.create();
      }
    },
    create() {
      ApiService.post(this.mainRoute, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.getData();
      }).catch((error) => {
        this.validation = error.response ? error.response.data.errors : null;
      });
    },
    update() {
      ApiService.put(this.mainRoute + '/' + this.idEdit, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
      }).catch((error) => {
        this.validation = error.response ? error.response.data.errors : null;
      });
    },
    getData() {
      this.reloadUploadAttachment = false;
      ApiService.get(this.mainRoute + '/get').then((response) => {
        this.isEditing = true;
        this.data = response.data.data;
        this.idEdit = this.data.id;
        this.logo_url = response.data.data.logo_url;
        this.reloadUploadAttachment = true;
      });
    },
    listenerAttachment(event) {
      if (event) {
        this.logo_url = event.pathDB;
        this.data.logo = event.name;
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.template_design")}]);
    this.getData();
  },
};
</script>


